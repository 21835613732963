<template>
    <authorization-wrapper>
        <reset-password :error="error" @submit="submit" @clearError="clearError()" />
    </authorization-wrapper>
</template>

<script>
    import AuthorizationWrapper from "../components/modules/AuthorizationWrapper";
    import ResetPassword from "../components/pages/ResetPassword";
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: "PasswordResetView",
        components: {ResetPassword, AuthorizationWrapper},
        computed: {
          ...mapGetters("errorModule", ["getERROR"])
        },
        data() {
            return {
                error: false,
                token: ""
            }
        },
        methods: {
            ...mapActions("passwordActionsModule", ["RESET_PASSWORD"]),
            clearError() {
              this.error = false
            },
            submit(form) {
                if (this.token?.length > 0) {
                    let refForm = {...form}
                    refForm.token = this.token
                    this.RESET_PASSWORD(refForm).then((answer) => {
                        if (!answer) {
                            this.error = this.getERROR?.length > 0
                            if (this.error) {
                                // open pop up
                                this.$toast.error(`${this.getERROR}`)
                            }
                        } else {
                            this.$router.push('/cabinet/profile')
                        }
                    })
                } else {
                    this.$router.push('/')
                }
            }
        },
        mounted() {
            this.token = this.$route.query?.token
        }
    }
</script>

<style lang="scss">
</style>