<template>
    <div class="request-password">
        <div class="form-container">
            <h2>Восстановление пароля</h2>
            <form @submit.prevent="submit">
                <rwm-text-field
                        class="pass"
                        v-model="form.password"
                        :error="errors.password"
                        :type="isOpenPass ? 'text' : 'password'"
                        label="Придумайте новый пароль"
                        @blur="validate('password')"
                        @onAppend="isOpenPass=!isOpenPass"
                        @focus="$emit('clearError')"
                >
                    <div slot="append-icon">
                        <transition name="fade">
                            <v-icon v-if="isOpenPass" @click="isOpenPass=!isOpenPass">view</v-icon>
                            <v-icon v-else @click="isOpenPass=!isOpenPass">close</v-icon>
                        </transition>
                    </div>
                </rwm-text-field>

                <rwm-text-field
                        class="pass"
                        v-model="form.passwordRepeat"
                        :error="errors.passwordRepeat"
                        :type="isOpenConfPass ? 'text' : 'password'"
                        label="Повторите пароль"
                        @blur="validate('passwordRepeat')"
                        @onAppend="isOpenConfPass=!isOpenConfPass"
                        @focus="$emit('clearError')"
                >
                    <div slot="append-icon">
                        <transition name="fade">
                            <v-icon v-if="isOpenConfPass" @click="isOpenConfPass=!isOpenConfPass">view</v-icon>
                            <v-icon v-else @click="isOpenConfPass=!isOpenConfPass">close</v-icon>
                        </transition>
                    </div>
                </rwm-text-field>
                <p class="info">Пароль должен быть длиной не менее 8 символов, содержать цифры, буквы латиницей верхнего и нижнего регистра, а также спецсимволы: !, @, #, $, %, ^, &, *, _</p>
                <rwm-button width="100%" type="submit">Сменить пароль</rwm-button>
            </form>
        </div>
    </div>
</template>

<script>
    import RwmTextField from "../RWMTextField/RwmTextField";
    import RwmButton from "../RWMButton/RwmButton";
    import VIcon from "../icons/VIcon";

    export default {
        name: "RequestPassword",
        components: {VIcon, RwmButton, RwmTextField},
        props: {
            error: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isOpenPass: false,
                isOpenConfPass: false,
                form: {
                    password: "",
                    passwordRepeat: ""
                },
                errors: {
                    password: false,
                    passwordRepeat: false
                }
            }
        },
        methods: {
            submit() {
                if (this.isValid()) {
                    let {password, passwordRepeat} = this.form
                    this.$emit('submit', {password: password, password_repeat: passwordRepeat})
                }
            },
            isValid() {
                let valid = false
                for (let form in this.form) {
                    this.validate(form)
                }
                for (let err in this.errors) {
                    if (this.errors[err]) {
                        valid = true
                    }
                }
                return !valid
            },
            validate(param) {
                if (param.match('password')) {
                    let pattern = /(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/g
                    pattern.test(this.form[param]) ? this.errors[param] = false : this.errors[param] = true
                }
                if (param.match('passwordRepeat')) {
                    this.form[param]?.length > 0 && this.form.password.match(this.form[param]) ? this.errors[param] = false : this.errors[param] = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .request-password {
        flex: 1 1 auto;
        padding: 60px 42px 0 42px;
        text-align: left;
        @media screen and (min-width: 961px) {
            padding: 96px 0 0;
        }
        & .form-container {
            width: 552px;
            padding: 96px;
            margin: auto;
            box-sizing: border-box;
            border: 2px solid #E5EAEE;
            border-radius: 15px;


            & h2 {
                margin-bottom: 32px;
                color: $RWM-dark;
            }

            & form {
                & .pass {
                    margin-bottom: 32px;
                }
            }
            & .info {
                margin-bottom: 32px;
                font-size: 12px;
                line-height: 16px;
                color: $RWM-dark;
            }
        }
    }
</style>